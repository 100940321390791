import { myAxios ,privateAxios} from "./helper";

export const getCommentsByPost = (postId,pageN,pageS,sortBy,sortDir) => {
  return myAxios
    .get("/api/post/" + postId + "/comments?pageN="+pageN+"&pageS="+pageS+"&sortBy="+sortBy+"&sortDir="+sortDir)
    .then((response) => response.data);
};

export const createComment = (userId, postId,comment) => {
  return privateAxios
    .post("/api/usr/" + userId + "/post/" + postId + "/comment",comment)
    .then((response) => response.data);
};
