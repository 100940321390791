import Base from "../components/Base";
import NewFeed from "../components/NewFeed";
import TabTitle from "../Util/TabTitle";
const Home = () => {
  TabTitle("Home");
  return (
    <Base>
      <NewFeed />
    </Base>
  );
};

export default Home;
