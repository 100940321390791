import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Home from "./pages/home";
import About from "./pages/about";

import { ToastContainer } from "react-toastify";
import Userdashboard from "./pages/user-routes/userdashboard";
import Privateroute from "./components/Privateroute";
import ProfileInfo from "./pages/user-routes/ProfileInfo";
import ReadMorePage from "./pages/ReadMorePage";
import UpdatePost from "./pages/UpdatePost";
import UserUpdate from "./pages/user-routes/UserUpdate";
import CreateUser from "./pages/admin-routes/createUser";
import AdminPrivateRoute from "./pages/admin-routes/AdminPrivateRoute";
import RefreshTokenPage from "./pages/RefreshTokenPage";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import UpdateUserAdmin from "./pages/admin-routes/updateUserAdmin";
import ResetForgotPassword from "./pages/ResetForgotPassword";
import VerifyOtpPassword from "./pages/VerifyOtpPassword";
import EmailVerification from "./pages/emailVerification";

function App() {
  return (
    <PrimeReactProvider>
      <BrowserRouter>
        <ToastContainer position="top-center" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/passwordrecovery" element={<ResetForgotPassword />} />
          <Route path="/resetPassword" element={<VerifyOtpPassword />} />
          <Route path="/post/:postId" element={<ReadMorePage />} />

          <Route path="/api/verify" element={<EmailVerification />} />

          <Route path="/user" element={<Privateroute />}>
            <Route path="dashboard" element={<Userdashboard />} />
            <Route path="profileinfo" element={<ProfileInfo />} />
            <Route path="updatePost/:postId" element={<UpdatePost />} />
            <Route path="userUpdate/:userId" element={<UserUpdate />} />
            <Route path="refreshToken" element={<RefreshTokenPage />} />
          </Route>
          <Route path="/admin" element={<AdminPrivateRoute />}>
            <Route path="createUser" element={<CreateUser />} />
            <Route
              path="userUpdateAdmin/:userId"
              element={<UpdateUserAdmin />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </PrimeReactProvider>
  );
}

export default App;
