import React, { useEffect, useState, useRef } from "react";
import Base from "../components/Base";
import { useParams } from "react-router-dom";
import { getOnePost } from "../services/postService";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
  CardImg,
  CardFooter,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Label,
  Form,
  FormGroup,
  Button,
  PaginationItem,
  Pagination,
  PaginationLink,
} from "reactstrap";
import { createComment, getCommentsByPost } from "../services/commentService";
import JoditEditor from "jodit-react";
//import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../auth";
import { getCurrentUserDetail } from "../auth";
import { BASE_URL } from "../services/helper";
import TabTitle from "../Util/TabTitle";

import { Interweave } from "interweave";
const ReadMorePage = () => {
  TabTitle("ReadMore");
  const { postId } = useParams();
  const editor = useRef(null);
  //const navigate=Navigate();

  const [postComment, setPostComment] = useState({
    commentContent: "",
  });
  const [readMore, SetReadMore] = useState({
    postId: "",
    postTitle: "",
    postContent: "",
    createdDate: "",
    category: {
      categoryId: "",
      categoryTitle: "",
      categoryDescription: "",
    },
    user: {
      userId: "",
      userName: "",
      userEmail: "",
      userAbout: "",
      roles: [],
    },
    comments: [
      {
        commentId: "",
        commentContent: "",
      },
    ],
  });

  const handlecommentForm = (event) => {
    event.preventDefault();
    let user = getCurrentUserDetail();
    let userId = user.id;

    createComment(userId, readMore.postId, postComment)
      .then((createcommentdata) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const [commentFilter, SetCommentFilter] = useState({
    pageN: "0",
    pageS: "5",
    sortBy: "createdDate",
    sortDir: "asc",
  });

  const changeComment = (pageN, pageS, sortBy, sortDir) => {
    //console.log(pageN);

    getCommentsByPost(
      postId,
      commentFilter.pageN,
      commentFilter.pageS,
      commentFilter.sortBy,
      commentFilter.sortDir
    )
      .then((data) => {
        //console.log(data);
        SetComments(data);
        SetCommentFilter({
          pageN: pageN,
          pageS: pageS,
          sortBy: sortBy,
          sortDir: sortDir,
        });
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Error: Unable in loading comments");
      });
  };

  const [comments, SetComments] = useState({
    comment: [
      {
        commentId: "",
        commentContent: "",
        createdDate: "",
        user: {
          userId: "",
          userName: "",
          userEmail: "",
          userAbout: "",
          roles: [
            {
              id: "",
              roleName: "",
            },
          ],
        },
      },
    ],
    pageNumber: "",
    pageSize: "",
    totalElements: "",
    totalPages: "",
    lastPage: false,
  });

  useEffect(() => {
    //console.log(postId);
    getOnePost(postId)
      .then((data) => {
        //console.log(data);
        SetReadMore(data);
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Error: Unable in loading posts");
      });

    getCommentsByPost(
      postId,
      commentFilter.pageN,
      commentFilter.pageS,
      commentFilter.sortBy,
      commentFilter.sortDir
    )
      .then((commentData) => {
        console.log(commentData);
        SetComments(commentData);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error: Unable in loading comments");
      });
  }, [
    postId,
    commentFilter.pageN,
    commentFilter.pageS,
    commentFilter.sortBy,
    commentFilter.sortDir,
  ]);

  const handleComment = (event, property) => {
    setPostComment({ ...postComment, [property]: event });
  };

  const resetComment = () => {
    setPostComment({
      commentContent: "",
    });
  };

  return (
    <Base>
      <Container className="mt-4">
        <Card className="my-2 shadow">
          <CardHeader tag="h5">
            Category: {readMore.category.categoryTitle}
          </CardHeader>
          <CardImg
            alt="Post Image"
            src={BASE_URL + "/api/get/image/" + postId}
            style={{
              height:400,
            }}
            top
            width="100%"
          />

          <CardBody>
            <CardTitle className="text-center" tag="h3">
              {readMore.postTitle}
            </CardTitle>

            <CardText>
              <Interweave content={readMore.postContent} />
            </CardText>
          </CardBody>
          <CardText className="px-2">
            <b>Comments:</b>{" "}
          </CardText>

          <ListGroup flush>
            {comments.comment?.map((comm) => (
              <ListGroupItem key={comm.commentId}>
                <CardBody>
                  <CardText>
                    <Interweave content={comm.commentContent} />
                  </CardText>
                </CardBody>
                <CardFooter>
                  Commented By: <b>{comm.user.userName}</b> on{" "}
                  <b>{new Date(comm.createdDate).toDateString()}</b>
                </CardFooter>
              </ListGroupItem>
            ))}
          </ListGroup>

          <Container className="mt-3">
            <Pagination>
              <PaginationItem disabled={comments.pageNumber === 0}>
                <PaginationLink
                  previous
                  onClick={() =>
                    changeComment(
                      --comments.pageNumber,
                      commentFilter.pageS,
                      commentFilter.sortBy,
                      commentFilter.sortDir
                    )
                  }
                >
                  Previous
                </PaginationLink>
              </PaginationItem>

              {[...Array(comments?.totalPages)].map((item, index) => (
                <PaginationItem
                  onClick={() =>
                    changeComment(
                      index,
                      commentFilter.pageS,
                      commentFilter.sortBy,
                      commentFilter.sortDir
                    )
                  }
                  active={index === comments.pageNumber}
                  key={index}
                >
                  <PaginationLink>{index + 1}</PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={comments.lastPage === true}>
                <PaginationLink
                  next
                  onClick={() =>
                    changeComment(
                      1 + comments.pageNumber,
                      commentFilter.pageS,
                      commentFilter.sortBy,
                      commentFilter.sortDir
                    )
                  }
                >
                  Next
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Container>

          {isLoggedIn() ? (
            <Form onSubmit={handlecommentForm}>
              <Label for="postComment" className="px-3">
                {" "}
                <b>Post Comment: </b>
              </Label>
              <FormGroup>
                <JoditEditor
                  ref={editor}
                  id="postComment"
                  value={postComment.commentContent}
                  name="postComment"
                  tabIndex={1} // tabIndex of textarea
                  // preferred to use only this option to update the content for performance reasons
                  onChange={(e) => handleComment(e, "commentContent")}
                />
              </FormGroup>
              <Container className="text-center mt-4 mb-4">
                <Button type="submit" color="success">
                  Post Comment
                </Button>
                <Button
                  color="danger"
                  type="reset"
                  className="ms-2"
                  onClick={resetComment}
                >
                  Reset Comment
                </Button>
              </Container>
            </Form>
          ) : (
            <CardText className="px-3 text-center">
              <b>Please Sign In to Comment</b>
            </CardText>
          )}

          <CardFooter>
            Created By: <b>{readMore.user.userName}</b> on{" "}
            <b>{new Date(readMore.createdDate).toDateString()}</b>
          </CardFooter>
        </Card>
      </Container>
    </Base>
  );
};

export default ReadMorePage;
