export const isLoggedIn = () => {
  let data = localStorage.getItem("data");

  if (data != null) {
    return true;
  } else {
    return false;
  }
};
export const doLogin = (data, next) => {
  localStorage.setItem("data", JSON.stringify(data));

  next();
};

export const doLoggedOut = (next) => {
  localStorage.removeItem("data");
  next();
};

export const getCurrentUserDetail = () => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem("data"))?.user;
  } else {
    return undefined;
  }
};

export const getToken = () => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem("data")).token;
  } else {
    return null;
  }
};

export const isAdmin = () => {
  let data = localStorage.getItem("data");
  let role = JSON.parse(data)?.user.userRole[0];
  if (role === "ROLE_ADMIN") {
    return true;
  } else {
    return false;
  }
};

export const isUser = () => {
  let data = localStorage.getItem("data");
  let role = JSON.parse(data)?.user.userRole[0];
  if (role === "ROLE_USER") {
    return true;
  } else {
    return false;
  }
};

export const getTokenExpiration = () => {
  return JSON.parse(localStorage.getItem("data")).tokenExpiration;
};

export const autoLogout = (tokenExpirationDate, next) => {
  setTimeout(() => {
    doLoggedOut(next);
  }, tokenExpirationDate);
};

export const refreshToken = (tokenExpirationDate, next) => {
  setTimeout(() => {
    next();
  }, tokenExpirationDate);
};

export const getRefreshTokenId = () => {
  return JSON.parse(localStorage.getItem("data")).refreshTokenId;
};
