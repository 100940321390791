import { React, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  CardFooter,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Container,
  Spinner,
} from "reactstrap";
import {
  getAllPost,
  getPostByCategory,
  searchPost,
} from "../services/postService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllCategory } from "../services/categoryService";
import TabTitle from "../Util/TabTitle";
import { NavLink as ReactLink } from "react-router-dom";

const Post = () => {
  TabTitle("New Feed");
  const [postContent, setPostContent] = useState({
    content: [],
    pageNumber: "",
    pageSize: "",
    totalElements: "",
    totalPages: "",
    lastPage: false,
  });

  const [searchKeyword, setSearchKeyword] = useState({
    searchWord: "",
  });

  const [postFilter, setPostFilter] = useState({
    pageN: "0",
    pageS: "5",
    sortBy: "postId",
    sortDir: "asc",
  });

  const [flag, setFlag] = useState({
    flagBoolean: false,
    selectedCatId: "",
  });

  const [categories, setCategories] = useState([]);

  const handleSearchKeyword = (event) => {
    setSearchKeyword({ ...searchKeyword, searchWord: event.target.value });
  };

  const handlePostFilter = (value, property) => {
    setPostFilter({ ...postFilter, [property]: value });
  };

  const resetPost = () => {
    setPostContent({
      content: [],
      pageNumber: "",
      pageSize: "",
      totalElements: "",
      totalPages: "",
      lastPage: "",
    });
  };

  useEffect(() => {
    getAllPost("0", "5", "postId", "asc")
      .then((postData) => {
        setPostContent(postData);
      })
      .catch((postError) => {});

    getAllCategory()
      .then((categoryData) => {
        setCategories(categoryData);
      })
      .catch((categoryError) => {});
  }, []);

  const changePage = (pageN, pageS, sortBy, sortDir) => {
    if (flag.flagBoolean === true) {
      getPostByCategory(flag.selectedCatId, pageN, pageS, sortBy, sortDir)
        .then((data) => {
          setPostContent({
            content: [...postContent.content, ...data.content],
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            lastPage: data.lastPage,
          });
        })
        .catch((error) => {
          toast.error("Error: Unable in loading posts");
        });
    }

    if (flag.flagBoolean === false) {
      getAllPost(pageN, pageS, sortBy, sortDir)
        .then((data) => {
          setPostContent({
            content: [...postContent.content, ...data.content],
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            lastPage: data.lastPage,
          });
        })
        .catch((error) => {
          toast.error("Error: Unable in loading posts");
        });
    }
  };

  const searchPage = (event) => {
    event.preventDefault();
    resetPost();

    if (searchKeyword.searchWord !== "") {
      searchPost(
        searchKeyword.searchWord,
        postFilter.pageN,
        postFilter.pageS,
        postFilter.sortBy,
        postFilter.sortDir
      )
        .then((searchData) => {
          setPostContent(searchData);
        })
        .catch((error) => {
          toast.error("Unable to perform search");
        });
    }

    if (searchKeyword.searchWord === "") {
      getAllPost(
        postFilter.pageN,
        postFilter.pageS,
        postFilter.sortBy,
        postFilter.sortDir
      )
        .then((data) => {
          setPostContent(data);
        })
        .catch((error) => {
          toast.error("Error: Unable in loading posts");
        });
    }
  };

  const searchByCategory = (event, catId) => {
    event.preventDefault();

    getPostByCategory(
      catId,
      postFilter.pageN,
      postFilter.pageS,
      postFilter.sortBy,
      postFilter.sortDir
    )
      .then((data) => {
        resetPost();
        setPostContent(data);
        setFlag({ ...flag, flagBoolean: true, selectedCatId: catId });
      })
      .catch((error) => {
        toast.error("Error: Unable in loading posts");
      });
  };

  const resetData = (event) => {
    event.preventDefault();
    setSearchKeyword({
      searchWord: "",
    });
    setFlag({ ...flag, flagBoolean: false, selectedCatId: "" });

    setPostFilter({
      pageN: "0",
      pageS: "5",
      sortBy: "postId",
      sortDir: "asc",
    });

    setPostContent({
      content: [],
      pageNumber: "",
      pageSize: "",
      totalElements: "",
      totalPages: "",
      lastPage: "",
    });

    getAllPost(
      postFilter.pageN,
      postFilter.pageS,
      postFilter.sortBy,
      postFilter.sortDir
    )
      .then((data) => {
        setPostContent(data);
      })
      .catch((error) => {
        toast.error("Error: Unable in loading posts");
      });
  };

  return (
    <Container className="mt-3">
      {/* Search Functionality of the Blog Start */}
      {/* {JSON.stringify(searchKeyword)} */}
      <Card
        color="success"
        outline
        style={{
          width: "auto",
        }}
      >
        <CardHeader className="text-center" tag="h1">
          Search Posts
        </CardHeader>
        <Form className="px-3" onSubmit={searchPage}>
          <FormGroup>
            <Row lg={6}>
              <Col>
                <Label>Sort Order:</Label>
              </Col>
              <Col>
                <FormGroup check>
                  <Input
                    name="sortDir"
                    type="radio"
                    onChange={() => handlePostFilter("asc", "sortDir")}
                    defaultChecked
                  />{" "}
                  <Label>Ascending</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Input
                    name="sortDir"
                    type="radio"
                    onChange={() => handlePostFilter("dsc", "sortDir")}
                  />{" "}
                  <Label>Descending</Label>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row lg={6}>
              <Col>
                <Label>Sort By:</Label>
              </Col>
              <Col>
                <FormGroup check>
                  <Input
                    name="SortBy"
                    type="radio"
                    onChange={() => handlePostFilter("postTitle", "SortBy")}
                    defaultChecked
                  />{" "}
                  <Label>Title</Label>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Input
                    name="SortBy"
                    type="radio"
                    onChange={() => handlePostFilter("createdDate", "SortBy")}
                  />{" "}
                  <Label>Date</Label>
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="searchKeyword">Search by Title:</Label>
            <Input
              id="searchKeyword"
              name="searchKeyword"
              placeholder="Title Keyword Here..."
              type="search"
              value={searchKeyword.searchWord}
              onChange={(e) => handleSearchKeyword(e)}
            />
          </FormGroup>
          <FormGroup>
            <div className="text-center">
              <Button outline type="submit" color="success">
                Search
              </Button>
              <Button
                onClick={(e) => resetData(e)}
                outline
                color="danger"
                type="reset"
                className="ms-2"
              >
                Reset
              </Button>
            </div>
          </FormGroup>
        </Form>
      </Card>

      {/* Search Functionality of the Blog End */}
      <Card
        className="mt-1 mb-1"
        style={{
          width: "auto",
        }}
        color="success"
        outline
      >
        <CardHeader className="text-center">
          <h1>Posts: ({postContent.totalElements})</h1>
          {/* {JSON.stringify(postFilter)} */}
        </CardHeader>
      </Card>
      <Row>
        <Col xs="auto">
          <Card
            style={{
              width: "auto",
            }}
            color="success"
            outline
          >
            <CardHeader>Search By Category:</CardHeader>
            <ListGroup flush>
              {categories?.map((category) => (
                <ListGroupItem
                  key={category.categoryId}
                  type="button"
                  className="btn btn-outline-success btn-sm"
                  onClick={(event) =>
                    searchByCategory(event, category.categoryId)
                  }
                >
                  {category.categoryTitle}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Card>
        </Col>

        <Col>
          {/* Post Card Start */}

          <InfiniteScroll
            dataLength={postContent.content.length}
            next={() =>
              changePage(
                postContent.pageNumber + 1,
                postFilter.pageS,
                postFilter.sortBy,
                postFilter.sortDir
              )
            }
            hasMore={!postContent.lastPage} // Replace with a condition based on your data source
            loader={
              <div className="text-center mt-4 spinner-border-md ">
                <Spinner color="success">Loading...</Spinner>
              </div>
            }
            endMessage={
              <h4 className="text-center ">No more posts to load.</h4>
            }
          >
            {postContent.content?.map((post) => (
              <Card
                key={post.postId}
                style={{
                  width: "auto",
                }}
                className="mb-3 shadow"
                color="success"
                outline
              >
                <CardBody>
                  <CardTitle className="text-center" tag="h5">
                    {post.postTitle}
                  </CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    <div className="text-success">
                      <b>Category: {post.category.categoryTitle}</b>
                    </div>
                  </CardSubtitle>
                </CardBody>

                <CardBody>
                  <CardText
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: post.postContent?.substring(0, 30) + "...",
                    }}
                  ></CardText>
                  <div className="col-md-12 text-center">
                    <Link
                      to={"/post/" + post.postId}
                      type="button"
                      className="btn btn-outline-success "
                      tag={ReactLink}
                    >
                      Read More
                    </Link>
                  </div>
                </CardBody>

                <CardFooter>
                  Created By: <b>{post.user.userName}</b> on{" "}
                  <b>{new Date(post.createdDate).toDateString()}</b>
                </CardFooter>
              </Card>
            ))}
          </InfiniteScroll>
          {/* {error && <p>Error: {error.message}</p>} */}

          {/* Post Card End */}
        </Col>
      </Row>
    </Container>
  );
};

export default Post;
