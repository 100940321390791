import React, { useEffect, useState } from "react";
import TabTitle from "../../Util/TabTitle";
import { useNavigate, useParams } from "react-router-dom";
import { getUserByIdAdmin, updateUserAdmin } from "../../services/user-service";
import { toast } from "react-toastify";
import Base from "../../components/Base";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  FormFeedback,
} from "reactstrap";

const UpdateUserAdmin = () => {
  TabTitle("UserUpdateAdmin");
  const { userId } = useParams();

  const navigate = useNavigate();

  const initialUserState = {
    userId: "",
    userName: "",
    userEmail: "",
    userPassword: "",
    userAbout: "",
    userImage: "",
    userRole: "",
  };

  const [user, setUser] = useState(initialUserState);

  const handleFormChange = (event, property) => {
    setUser({ ...user, [property]: event.target.value });
  };

  const cancelUpdate = (event) => {
    event.preventDefault();
    navigate("/admin/createUser");
  };

  const [checkPassword, setCheckPassword] = useState({
    message: null,
  });
  const handleCheckPassword = (value) => {
    setCheckPassword({ ...checkPassword, message: value });
  };

  const checkPasswordValidation = (event) => {
    let passValue = event.target.value;
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(passValue)) {
      handleCheckPassword("Password must not contain Whitespaces.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Uppercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Lowercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_])/;
    if (!isContainsSymbol.test(passValue)) {
      handleCheckPassword("Password must contain at least one Special Symbol.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(passValue)) {
      handleCheckPassword("Password must contain at least one Digit.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isValidLength = /^.{10,16}$/;
    if (!isValidLength.test(passValue)) {
      handleCheckPassword("Password must be 10-16 Characters Long.");

      return;
    } else {
      handleCheckPassword(null);
    }

    handleCheckPassword(null);
  };

  useEffect(() => {
    getUserByIdAdmin(userId)
      .then((userData) => {
        setUser(userData);
      })
      .catch((userError) => {
        toast.error("Error: Unable in loading User details");
        navigate("/user/profileinfo");
      });
  }, [userId, navigate]);

  const submitForm = (event) => {
    event.preventDefault();

    updateUserAdmin(user, userId)
      .then((response) => {
        //console.log(response);
        toast.success(response.message);
        navigate("/admin/createUser");
      })
      .catch((error) => {
        //console.log(error);
        toast.error(error.response.data.message);
      });
  };
  return (
    <Base>
      <Container className="mt-3">
        {/* {JSON.stringify(data)} */}

        <Card className="shadow" color="success" outline>
          <CardHeader className="text-center">
            <h3>Update User</h3>
          </CardHeader>
          <CardBody>
            {/*creating signup form*/}
            <Form onSubmit={submitForm}>
              <FormGroup>
                <InputGroup>
                  <InputGroupText style={{ padding: "0.375rem 0.3rem" }}>
                    User Name
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder="Enter Name Here"
                    id="userName"
                    onChange={(e) => handleFormChange(e, "userName")}
                    value={user.userName}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupText style={{ padding: "0.375rem 1.65rem" }}>
                    Email
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder="Enter Email Here"
                    id="userEmail"
                    onChange={(e) => handleFormChange(e, "userEmail")}
                    value={user.userEmail}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupText style={{ padding: "0.375rem 0.6rem" }}>
                    Password
                  </InputGroupText>
                  <Input
                    type="password"
                    placeholder="Enter Password Here"
                    id="userPassword"
                    onChange={(e) => {
                      handleFormChange(e, "userPassword");
                      checkPasswordValidation(e);
                    }}
                    value={user.userPassword}
                    invalid={checkPassword.message !== null ? true : false}
                  />
                  {checkPassword.message !== null && (
                    <FormFeedback>{checkPassword.message}</FormFeedback>
                  )}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupText style={{ padding: "0.375rem 1.45rem" }}>
                    About
                  </InputGroupText>

                  <Input
                    id="userAbout"
                    placeholder="Enter Here"
                    type="textarea"
                    style={{ height: "250px" }}
                    onChange={(e) => handleFormChange(e, "userAbout")}
                    value={user.userAbout}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupText style={{ padding: "0.375rem 1.85rem" }}>
                    Role
                  </InputGroupText>
                  <FormGroup className="ms-3 mt-2" check inline>
                    <Input
                      type="radio"
                      name="userRole"
                      onChange={(e) => handleFormChange(e, "userRole")}
                      value={"ROLE_USER"}
                      checked={user.userRole === "ROLE_USER"}
                    />
                    User
                  </FormGroup>

                  <FormGroup className="mt-2" check inline>
                    <Input
                      type="radio"
                      name="userRole"
                      onChange={(e) => handleFormChange(e, "userRole")}
                      value={"ROLE_ADMIN"}
                      checked={user.userRole === "ROLE_ADMIN"}
                    />
                    Admin
                  </FormGroup>
                </InputGroup>
              </FormGroup>

              <Container className="text-center mt-4">
                <Button disabled={checkPassword.message !== null} type="submit" outline color="success">
                  Update
                </Button>
                <Button
                  onClick={cancelUpdate}
                  outline
                  color="danger"
                  type="reset"
                  className="ms-2"
                  
                >
                  Cancel
                </Button>
              </Container>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </Base>
  );
};

export default UpdateUserAdmin;
