import { toast } from "react-toastify";
import Base from "../components/Base";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from "reactstrap";
import { useState } from "react";
import { forgotPassword } from "../services/user-service";
import TabTitle from "../Util/TabTitle";
import { useNavigate } from "react-router-dom";

const ResetForgotPassword = () => {
  TabTitle("Reset Password");
  const [resetPassword, setResetPassword] = useState({
    userEmail: "",
  });
  const handleChange = (event, property) => {
    setResetPassword({ ...resetPassword, [property]: event.target.value });
  };

  const navigate = useNavigate();

  const [error, setError] = useState({
    errors: {},
    isError: false,
  });

  const handlesubmitForm = (event) => {
    event.preventDefault();

    forgotPassword(resetPassword)
      .then((response) => {
        toast.success("Please check your email for reset password code. ");
        navigate("/resetPassword");
      })
      .catch((error) => {
        console.log(error);
        setError({ errors: error, isError: true });
        if (
          error.response.data.message ===
          "Email address is not found. Please Sign up."
        ) {
          toast.error("Email address is not found. Please Sign up.");
        }
      });
  };

  return (
    <Base>
      <Container>
        <Row className="mt-4">
          <Col sm={{ size: 6, offset: 3 }}>
            <Card className="shadow" color="success" outline>
              <CardHeader className="text-center">
                <h3>Fill Information to Reset Password</h3>
              </CardHeader>
              <CardBody>
                {/*creating login form*/}
                <Form onSubmit={handlesubmitForm}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.65rem" }}>
                        Email
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter Email Here"
                        id="userEmail"
                        value={resetPassword.userEmail}
                        onChange={(e) => handleChange(e, "userEmail")}
                        invalid={
                          error.errors.response?.data.userEmail ===
                          "Email address is not valid"
                        }
                      />
                      {error.errors.response?.data.userEmail ===
                        "Email address is not valid" && (
                        <FormFeedback>
                          {error.errors.response?.data.userEmail}
                        </FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>

                  <Container className="text-center mt-4">
                    <Button
                      disabled={resetPassword.userEmail === ""}
                      color="success"
                    >
                      Reset Password
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default ResetForgotPassword;
