import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmail } from "../services/user-service";
import Base from "../components/Base";
import { CardText } from "reactstrap";
import { ProgressSpinner } from "primereact/progressspinner";
import { toast } from "react-toastify";

const EmailVerification = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const navigate = useNavigate();

  useEffect(() => {
    verifyEmail(code)
      .then((response) => {
        navigate("/user/dashboard");
        toast.success("Email Verified.Please Login");
      })
      .catch((error) => {});
  }, [code, navigate]);

  return (
    <Base>
      <div className="mt-4 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center">
          <ProgressSpinner
            style={{ width: "150px", height: "150px" }}
            strokeWidth="4"
            fill="var(--surface-ground)"
          />
          <CardText className="text-center">
            Verifying Email Address...
          </CardText>
        </div>
      </div>
    </Base>
  );
};

export default EmailVerification;
