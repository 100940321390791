import React, { useEffect, useState } from "react";
import { NavLink as ReactLink, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
} from "reactstrap";
import {
  doLoggedOut,
  getCurrentUserDetail,
  isAdmin,
  isLoggedIn,
} from "../auth";

function CustomeNavbar(args) {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(undefined);

  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    setLogin(isLoggedIn());
    setUser(getCurrentUserDetail());
    setAdmin(isAdmin());
  }, []);
  const toggle = () => setIsOpen(!isOpen);

  const logout = () => {
    doLoggedOut(() => {
      setLogin(false);
      navigate("/login");
    });
  };
  return (
    <div>
      <Navbar expand="md" fixed="" className="px-4">
        <NavbarBrand tag={ReactLink} to="/about">
          Armeen
        </NavbarBrand>
        {/* {JSON.stringify(admin)} */}
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink tag={ReactLink} to="/">
                New Feed
              </NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                More
              </DropdownToggle>
              <DropdownMenu color="dark" dark expand="md" fixed="" end>
                <DropdownItem tag={ReactLink} to="/about">
                  About
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Nav navbar>
            {login && (
              <>
                <NavItem>
                  <NavLink tag={ReactLink} to="/user/dashboard">
                    Add post
                  </NavLink>
                </NavItem>

                {admin && (
                  <NavItem>
                    <NavLink tag={ReactLink} to="/admin/createUser">
                      Create User
                    </NavLink>
                  </NavItem>
                )}

                <NavItem>
                  <NavLink tag={ReactLink} to="/user/profileinfo">
                    Account
                  </NavLink>
                </NavItem>

                <NavbarText>{user.userEmail}</NavbarText>

                <NavItem>
                  <Button
                    color="info"
                    outline
                    className="mx-2 mt-1"
                    size="sm"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </NavItem>
              </>
            )}

            {!login && (
              <>
                <NavItem>
                  <NavLink tag={ReactLink} to="/login">
                    Login
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={ReactLink} to="/signup">
                    Signup
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default CustomeNavbar;
