import React, { useEffect, useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import {
  doLoggedOut,
  doLogin,
  getRefreshTokenId,
  getTokenExpiration,
  refreshToken,
} from "../auth";

import { useNavigate } from "react-router-dom";
import { createRefreshToken } from "../services/user-service";
import { toast } from "react-toastify";
import TabTitle from "../Util/TabTitle";

const RefreshTokenPage = (args) => {
  TabTitle("RefreshLogin");
  let navigate = useNavigate();
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(!modal);

  const [token, setToken] = useState({
    refreshTokenId: "",
  });

  useEffect(() => {
    setToken({ refreshTokenId: getRefreshTokenId() });
  }, []);

  const logout = () => {
    doLoggedOut(() => {
      navigate("/login");
    });
  };

  const handleRefreshToken = () => {
    // let tokenId = getRefreshTokenId();
    // console.log(tokenId);

    // console.log(token);
    createRefreshToken(token)
      .then((response) => {
        doLogin(response, () => {});

        navigate("/user/dashboard");

        let currentTime = new Date().getTime();
        let tokenExpirationTime = getTokenExpiration();
        // console.log(new Date(tokenExpirationTime));
        let tokenExpireTimer = tokenExpirationTime - currentTime;

        refreshToken(tokenExpireTimer, () => {
          navigate("/user/refreshToken");
        });
      })
      .catch((error) => {
        //   console.log(error);
        //   toast.error(error.response.data.message);

        toast.error("Please Sign in again.");

        doLoggedOut(() => {
          navigate("/login");
        });
      });
  };

  return (
    <div>
      <Modal isOpen={modal} {...args}>
        <ModalHeader toggle={toggle}>Stay Sign in or Logout</ModalHeader>
        <ModalBody>Do you want to stay Sign in?</ModalBody>
        <ModalFooter>
          <Container className="text-center mt-4">
            <Button
              color="success"
              onClick={() => {
                toggle();
                handleRefreshToken();
              }}
            >
              Yes
            </Button>{" "}
            <Button
              color="danger"
              className="ms-2"
              onClick={() => {
                toggle();
                logout();
              }}
            >
              Logout
            </Button>
          </Container>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RefreshTokenPage;
