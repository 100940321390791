
import Post from "./Post";


const NewFeed = () => {
 return(
   
 <Post></Post>
 
 ) 
};

export default NewFeed;
