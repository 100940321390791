import { useState } from "react";
import Base from "../components/Base";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupText,
  CardFooter,
} from "reactstrap";

import { toast } from "react-toastify";
import { loginuser } from "../services/user-service";

import { doLogin, getTokenExpiration, refreshToken } from "../auth";
import { Link, useNavigate } from "react-router-dom";
import TabTitle from "../Util/TabTitle";

const Login = () => {
  TabTitle("Login");
  const navigate = useNavigate();

  const [loginDetail, setLoginDetail] = useState({
    userEmail: "",
    userPassword: "",
  });

  const handleChange = (event, property) => {
    setLoginDetail({ ...loginDetail, [property]: event.target.value });
  };

  const [validation, setValidation] = useState({
    emailFlag: false,
    passwordFlag: false,
  });

  const handlesubmitForm = (event) => {
    event.preventDefault();

    if (loginDetail.userEmail === "") {
      return setValidation({
        emailFlag: true,
      });
    }

    if (loginDetail.userPassword === "") {
      return setValidation({
        passwordFlag: true,
      });
    }

    loginuser(loginDetail)
      .then((response) => {
        //console.log(response);

        doLogin(response, () => {});

        navigate("/user/dashboard");
        toast.success("Login successful");

        let currentTime = new Date().getTime();
        let tokenExpirationTime = getTokenExpiration();

        let tokenExpireTimer = tokenExpirationTime - currentTime;

        // console.log(new Date(tokenExpirationTime));

        refreshToken(tokenExpireTimer, () => {
          navigate("/user/refreshToken");
        });

        setLoginDetail({
          userEmail: "",
          userPassword: "",
        });
      })
      .catch((error) => {
        //console.log(error);
        toast.error(error.response.data.message);
      });

    setValidation({
      emailFlag: false,
      passwordFlag: false,
    });
  };

  return (
    <Base>
      <Container>
        <Row className="mt-4">
          <Col sm={{ size: 6, offset: 3 }}>
            <Card className="shadow" color="success" outline>
              <CardHeader className="text-center">
                <h3>Fill Information to Login</h3>
              </CardHeader>
              <CardBody>
                {/*creating login form*/}
                <Form onSubmit={handlesubmitForm}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.80rem" }}>
                        Email
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter Email Here"
                        id="userEmail"
                        value={loginDetail.userEmail}
                        onChange={(e) => handleChange(e, "userEmail")}
                        invalid={validation.emailFlag}
                      />
                      {validation.emailFlag && (
                        <FormFeedback>Email is required</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText>Password</InputGroupText>
                      <Input
                        type="password"
                        placeholder="Enter Password Here"
                        id="userPassword"
                        value={loginDetail.userPassword}
                        onChange={(e) => handleChange(e, "userPassword")}
                        invalid={validation.passwordFlag}
                      />
                      {validation.passwordFlag && (
                        <FormFeedback>Password is required</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>

                  <Container className="text-center mt-4">
                    <Button
                      disabled={
                        loginDetail.userEmail === "" ||
                        loginDetail.userPassword === ""
                      }
                      color="success"
                    >
                      Login
                    </Button>
                  </Container>
                </Form>
              </CardBody>
              <CardFooter>
                <Link to={"/passwordrecovery"}>Reset/Forgot Password</Link>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default Login;
