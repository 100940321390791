import { myAxios, privateAxios } from "./helper";

export const signup = (user) => {
  return myAxios.post("/api/register", user).then((response) => response.data);
};

export const loginuser = (loginDetail) => {
  return myAxios
    .post("/api/signin", loginDetail)
    .then((response) => response.data);
};

export const createUser = (user) => {
  return privateAxios.post("/api/user", user).then((response) => response.data);
};

export const getUserById = (userId) => {
  return myAxios.get("/api/user/" + userId).then((response) => response.data);
};

export const getUserByIdAdmin = (userId) => {
  return myAxios
    .get("/api/admin/user/" + userId)
    .then((response) => response.data);
};

export const getUserByEmail = (userEmail) => {
  return myAxios
    .get("/api/user/userEmail/" + userEmail)
    .then((response) => response.data);
};

export const getAllUser = (pageN, pageS, sortBy, sortDir) => {
  return myAxios
    .get(
      "/api/users?pageN=" +
        pageN +
        "&pageS=" +
        pageS +
        "&sortBy=" +
        sortBy +
        "&sortDir=" +
        sortDir
    )
    .then((response) => response.data);
};

export const updateUser = (user, userId) => {
  return privateAxios
    .put("/api/user/" + userId, user)
    .then((response) => response.data);
};

export const updateUserAdmin = (user, userId) => {
  return privateAxios
    .put("/api/admin/user/" + userId, user)
    .then((response) => response.data);
};

export const deleteUserById = (userId) => {
  return privateAxios
    .delete("/api/deleteuser/" + userId)
    .then((response) => response.data);
};

export const uploadProfileImage = (userId, image) => {
  return privateAxios
    .post("/api/user/profileImage/" + userId, image)
    .then((response) => response.data);
};

export const getUserImageById = (userId) => {
  return privateAxios
    .get("/api/get/profileImage/" + userId)
    .then((response) => response.data);
};

export const deleteProfileImage = (userId) => {
  return privateAxios
    .delete("/api/delete/profileImage/" + userId)
    .then((response) => response.data);
};

export const createRefreshToken = (refreshTokenId) => {
  return myAxios
    .post("/api/refreshToken", refreshTokenId)
    .then((response) => response.data);
};

export const forgotPassword = (userEmail) => {
  return myAxios
    .post("/api/forgot-password", userEmail)
    .then((response) => response.data);
};

export const forgotPasswordOTP = (verifyOtp) => {
  return myAxios
    .post("/api/reset-password", verifyOtp)
    .then((response) => response.data);
};

export const verifyEmail = (code) => {
  return myAxios
    .get("/api/verify?code=" + code)
    .then((response) => response.data);
};
