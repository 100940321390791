import { React, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
  CardFooter,
  CardHeader,
  Container,
  ListGroup,
  ListGroupItem,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Dialog } from "primereact/dialog";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { deletePost, getPostByUser } from "../../services/postService";
import Base from "../../components/Base";
import { getCurrentUserDetail } from "../../auth";
import {
  deleteProfileImage,
  getUserById,
  getUserImageById,
  uploadProfileImage,
} from "../../services/user-service";
import TabTitle from "../../Util/TabTitle";
import Avatar from "react-avatar-edit";

const ProfileInfo = (args) => {
  TabTitle("profile");

  var defaultImage = require("../../defaultImage/defaultProfileImage.jpg");

  const [userContent, setUserContent] = useState({
    content: [],
    pageNumber: "",
    pageSize: "",
    totalElements: "",
    totalPages: "",
    lastPage: false,
  });

  const [user, setUser] = useState({
    id: "-1",
    userName: "",
    userEmail: "",
    userRole: "",
  });

  const [userDetails, setUserDetials] = useState({
    userId: "",
    userName: "",
    userEmail: "",
    userAbout: "",
    userImage: "",
    Role: [
      {
        id: "",
        roleName: "",
      },
    ],
  });

  const [postFilter, setPostFilter] = useState({
    pageN: "0",
    pageS: "5",
    sortBy: "postId",
    sortDir: "asc",
  });

  const [postModal, setPostModal] = useState(false);

  const [profilePhotoModal, setProfilePhotoModal] = useState(false);

  const [deletePostId, setDeletePostId] = useState({
    id: "",
  });

  const [dialogs, setDialogs] = useState(false);

  const [imgCrop, setImgCrop] = useState(false);

  const [profileImage, setprofileImage] = useState({
    userImage: "",
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUser(getCurrentUserDetail());

    if (user.id !== "-1") {
      getUserById(user.id)
        .then((userData) => {
          setUserDetials(userData);
        })
        .catch((userError) => {});

      getUserImageById(user.id)
        .then((profileImageData) => {
          setprofileImage(profileImageData);
        })
        .catch((userError) => {});

      getPostByUser(user.id, "0", "5", "postId", "asc")
        .then((postsData) => {
          setUserContent(postsData);
          setLoading(false);
        })
        .catch((postError) => {});
    }
  }, [user.id]);

  const togglePost = () => setPostModal(!postModal);
  const toggleProfilePhoto = () => setProfilePhotoModal(!profilePhotoModal);

  const handledeleteId = (event) => {
    setDeletePostId({ ...deletePostId, id: event.target.value });
  };

  const handlePostFilter = (value, property) => {
    setPostFilter({ ...postFilter, [property]: value });
  };

  const onCrop = (view) => {
    setImgCrop(view);
    setprofileImage({ ...profileImage, userImage: imgCrop });
  };

  const onClose = () => {
    setImgCrop(null);
  };

  const resetPost = () => {
    setUserContent({
      content: [],
      pageNumber: "",
      pageSize: "",
      totalElements: "",
      totalPages: "",
      lastPage: "",
    });
  };

  const saveImage = () => {
    uploadProfileImage(user.id, profileImage)
      .then((profileImageData) => {
        toast.success("Profile Image Uploaded");
      })
      .catch((userError) => {
        toast.error("Error: Unable to Upload Profile Image");
      });

    setDialogs(false);
  };

  const changePage = (pageN, pageS, sortBy, sortDir) => {
    if (user.id !== "-1") {
      getPostByUser(user.id, pageN, pageS, sortBy, sortDir)
        .then((data) => {
          setUserContent({
            content: [...userContent.content, ...data.content],
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            lastPage: data.lastPage,
          });
        })
        .catch((error) => {
          toast.error("Error: Unable in loading posts");
        });
    }
  };

  const searchPage = (event) => {
    event.preventDefault();
    resetPost();

    getPostByUser(
      user.id,
      postFilter.pageN,
      postFilter.pageS,
      postFilter.sortBy,
      postFilter.sortDir
    )
      .then((data) => {
        setUserContent(data);
      })
      .catch((error) => {
        toast.error("Error: Unable in loading posts");
      });
  };

  const handleDeletePost = () => {
    deletePost(deletePostId.id)
      .then((respone) => {
        toast.success(respone.message);

        resetPost();

        getPostByUser(
          user.id,
          postFilter.pageN,
          postFilter.pageS,
          postFilter.sortBy,
          postFilter.sortDir
        )
          .then((data) => {
            setUserContent(data);
          })
          .catch((error) => {});
      })
      .catch((deleteError) => {
        toast.error(deleteError.response.data.message);
      });
  };

  const handleDeleteProfilePhoto = () => {
    deleteProfileImage(user.id)
      .then((deleteData) => {
        toast.success(deleteData.message);
        setprofileImage({ userImage: "" });
      })
      .catch((error) => {
        toast.error("Error: Unable to delete photo");
      });
  };

  return (
    <Base>
      <Container className="mt-3">
        <Container className="d-flex justify-content-center mb-3">
          <Card
            style={{
              width: "40%",
            }}
          >
            <CardHeader className="text-center ">
              <b>Profile</b>
            </CardHeader>

            <div className="d-flex justify-content-center">
              <div className="profile_img text-center p-4">
                <div>
                  <img
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    src={
                      profileImage.userImage.length
                        ? profileImage.userImage
                        : defaultImage
                    }
                    alt={"ImgProfile"}
                    onClick={() => setDialogs(true)}
                  />

                  <Dialog
                    visible={dialogs}
                    header={() => (
                      <p>
                        <p
                          htmlFor=""
                          className="text-2x1 font-semibold textColor"
                        >
                          Upload Profile Image:
                          <br />
                        </p>
                        <h6 className="lead" color="red">
                          {" "}
                          Please move the circle to select <br /> profile
                          picture and then click save.
                        </h6>
                      </p>
                    )}
                    onHide={() => setDialogs(false)}
                  >
                    <div className="confirmation-content flex flex-column align-items-center">
                      <div className="flex flex-colum align-items-center mt-5 w12">
                        <div className="flex justify-content-around w-12 mt-4">
                          <div>
                            <Avatar
                              width={400}
                              height={300}
                              onCrop={onCrop}
                              onClose={onClose}
                            />
                          </div>
                          <Container className="mt-3 text-center">
                            <Button outline onClick={saveImage} color="success">
                              Save
                            </Button>
                          </Container>
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
            <CardBody>
              <CardTitle className="text-center text-success" tag="h5">
                <b>About</b>
              </CardTitle>
              <CardText className="text-center">
                {userDetails.userAbout}
              </CardText>
            </CardBody>
            <ListGroup flush>
              <ListGroupItem>
                <b className="text-success">User Name:</b>{" "}
                {userDetails.userName}
              </ListGroupItem>
              <ListGroupItem>
                <b className="text-success">User Email:</b> {user.userEmail}
              </ListGroupItem>
              <ListGroupItem>
                <b className="text-success">Role:</b>{" "}
                {user.userRole.toString().substring(5).charAt(0) +
                  user.userRole.toString().substring(6).toLowerCase()}
              </ListGroupItem>
            </ListGroup>
            <div className="col-md-12 text-center mt-3 mb-3">
              <Button onClick={() => setDialogs(true)} outline color="primary">
                Upload Photo
              </Button>
              <Link
                to={"/user/userUpdate/" + user.id}
                type="button"
                className="btn btn-outline-warning ms-2 "
              >
                Update
              </Link>

              <Button
                className="ms-2"
                outline
                color="danger"
                onClick={toggleProfilePhoto}
              >
                Delete Photo
              </Button>

              <div>
                <Modal
                  isOpen={profilePhotoModal}
                  toggle={toggleProfilePhoto}
                  {...args}
                >
                  <ModalHeader toggle={toggleProfilePhoto}>
                    Delete Profile Photo
                  </ModalHeader>
                  <ModalBody>
                    <b>Do you want to Delete your profile photo?</b>
                  </ModalBody>
                  <ModalFooter>
                    <Container className="text-center mt-4">
                      <Button
                        color="danger"
                        onClick={() => {
                          handleDeleteProfilePhoto();
                          toggleProfilePhoto();
                        }}
                      >
                        Yes
                      </Button>{" "}
                      <Button color="success" onClick={toggleProfilePhoto}>
                        No
                      </Button>
                    </Container>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </Card>
        </Container>
        {/* Search Functionality of the Blog Start */}
        <Card
          color="success"
          outline
          style={{
            width: "auto",
          }}
        >
          <CardHeader className="text-center" tag="h1">
            Search Posts
          </CardHeader>
          <Form className="px-3" onSubmit={searchPage}>
            <FormGroup>
              <Row lg={6}>
                <Col>
                  <Label>Sort Order:</Label>
                </Col>
                <Col>
                  <FormGroup check>
                    <Input
                      name="sortDir"
                      type="radio"
                      onChange={() => handlePostFilter("asc", "sortDir")}
                      defaultChecked
                    />{" "}
                    <Label>Ascending</Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Input
                      name="sortDir"
                      type="radio"
                      onChange={() => handlePostFilter("dsc", "sortDir")}
                    />{" "}
                    <Label>Descending</Label>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row lg={6}>
                <Col>
                  <Label>Sort By:</Label>
                </Col>
                <Col>
                  <FormGroup check>
                    <Input
                      name="SortBy"
                      type="radio"
                      onChange={() => handlePostFilter("postTitle", "SortBy")}
                      defaultChecked
                    />{" "}
                    <Label>Title</Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup check>
                    <Input
                      name="SortBy"
                      type="radio"
                      onChange={() => handlePostFilter("createdDate", "SortBy")}
                    />{" "}
                    <Label>Date</Label>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <div className="col-md-12 text-center">
                <Button
                  outline
                  type="submit"
                  color="success"
                  style={{
                    width: "50%",
                  }}
                >
                  Search
                </Button>
              </div>
            </FormGroup>
          </Form>
        </Card>
        {/* Search Functionality of the Blog End */}
        <Card
          className="mt-1 mb-1"
          style={{
            width: "auto",
          }}
          color="success"
          outline
        >
          <CardHeader className="text-center">
            <h1>Posts: ({userContent.totalElements})</h1>
          </CardHeader>
        </Card>

        {loading ? (
          <div className="text-center mt-4 spinner-border-md ">
            <Spinner color="success">Loading...</Spinner>
          </div>
        ) : (
          <Row>
            <Col>
              {/* Post Card Start */}

              <InfiniteScroll
                dataLength={userContent.content.length}
                next={() =>
                  changePage(
                    userContent.pageNumber + 1,
                    postFilter.pageS,
                    postFilter.sortBy,
                    postFilter.sortDir
                  )
                }
                hasMore={!userContent.lastPage}
                loader={
                  <div className="text-center mt-4 spinner-border-md ">
                    <Spinner color="success">Loading...</Spinner>
                  </div>
                }
                endMessage={
                  <h4 className="text-center ">No more posts to load.</h4>
                }
              >
                {userContent.content?.map((post) => (
                  <Card
                    key={post.postId}
                    style={{
                      width: "auto",
                    }}
                    className="mb-3 shadow"
                    color="success"
                    outline
                  >
                    <CardBody>
                      <CardTitle className="text-center" tag="h5">
                        {post.postTitle}
                      </CardTitle>
                      <CardSubtitle className="mb-2 text-muted" tag="h6">
                        <div className="text-success">
                          <b>Category: {post.category.categoryTitle}</b>
                        </div>
                      </CardSubtitle>
                    </CardBody>

                    <CardBody>
                      <CardText
                        className="text-center"
                        dangerouslySetInnerHTML={{
                          __html: post.postContent?.substring(0, 30) + "...",
                        }}
                      ></CardText>
                      <div className="col-md-12 text-center">
                        <Link
                          to={"/post/" + post.postId}
                          type="button"
                          className="btn btn-outline-success "
                        >
                          Read More
                        </Link>

                        <Link
                          to={"/user/updatePost/" + post.postId}
                          type="button"
                          className="btn btn-outline-warning ms-2 "
                        >
                          Update
                        </Link>
                      </div>
                      <div className="float-end">
                        <Button
                          value={post.postId}
                          key={post.postId}
                          outline
                          color="danger"
                          onClick={(e) => {
                            togglePost();
                            handledeleteId(e);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                      <div>
                        <Modal isOpen={postModal} toggle={togglePost} {...args}>
                          <ModalHeader toggle={togglePost}>
                            Delete Post
                          </ModalHeader>
                          <ModalBody>
                            <b>Do you want to Delete this post?</b>
                          </ModalBody>
                          <ModalFooter>
                            <Container className="text-center mt-4">
                              <Button
                                color="danger"
                                onClick={() => {
                                  handleDeletePost();
                                  togglePost();
                                }}
                              >
                                Yes
                              </Button>{" "}
                              <Button color="success" onClick={togglePost}>
                                No
                              </Button>
                            </Container>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </CardBody>

                    <CardFooter>
                      Created Date:{" "}
                      <b>{new Date(post.createdDate).toLocaleDateString()}</b>
                    </CardFooter>
                  </Card>
                ))}
              </InfiniteScroll>

              {/* Post Card End */}
            </Col>
          </Row>
        )}
      </Container>
    </Base>
  );
};

export default ProfileInfo;
