import { useEffect, useRef, useState, React } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Base from "../components/Base";
import { getOnePost, updatePost } from "../services/postService";
import { toast } from "react-toastify";
import { getCurrentUserDetail } from "../auth";
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
  Container,
  Row,
  Col,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { getAllCategory } from "../services/categoryService";

import { uploadImage } from "../services/postService";

import JoditEditor from "jodit-react";
import TabTitle from "../Util/TabTitle";

function UpdatePost() {
  TabTitle("UpdatePost");
  const { postId } = useParams();

  const [user, setUser] = useState({
    id: "-1",
    userName: "",
    userEmail: "",
    roles: "",
  });

  const [post, setPost] = useState({
    postTitle: "",
    postContent: "",

    category: {
      categoryId: "",
    },
    user: {
      userId: "",
    },
  });

  const [image, setImage] = useState(null);
  const [catFlag, setCatFlag] = useState(false);

  const [error, setError] = useState({
    errors: {},
    isError: false,
  });

  const [categories, setCategories] = useState([]);

  const editor = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    setUser(getCurrentUserDetail());
    // console.log("update post useEffect");
    if (user.id !== "-1") {
      getOnePost(postId)
        .then((postData) => {
          setPost(postData);
        })
        .catch((userError) => {
          toast.error("Error: Unable in loading User details");
        });

      getAllCategory()
        .then((catData) => {
          setCategories(catData);
        })
        .catch((error) => {
          toast.error("Error:Unable to load Categories");
        });
    }

    if (post.user.userId !== "") {
      if (post.user.userId !== user.id) {
        return navigate("/");
      }
    }
  }, [navigate, postId, post.user.userId, user.id]);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleCat = (event) => {
    setPost({
      ...post,
      category: {
        categoryId: event.target.value,
      },
    });
  };

  const handlePost = (event, property) => {
    setPost({ ...post, [property]: event });
  };

  const handlepostForm = (event) => {
    event.preventDefault();
    if (post.postContent.trim() === "") {
      return toast.error("Content is required");
    }

    if (post.category.categoryId === "-1") {
      setCatFlag(true);
      return;
    }

    updatePost(post, user.id, post.category.categoryId, postId)
      .then((response) => {
        // console.log(response);
        if (image !== null) {
          uploadImage(postId, image)
            .then((imageData) => {
              toast.success("Post image updated");
            })
            .catch((error) => {
              //console.log(error);
              toast.error("Error:Unable to update post Image");
            });
        }

        toast.success("Update successful");

        navigate("/post/" + postId);
      })
      .catch((error) => {
        //console.log(error);
        //toast.error("Error:Unable to update Post");
        setError({ errors: error, isError: true });
      });
  };

  const cancelUpdate = (event) => {
    event.preventDefault();
    navigate("/user/profileinfo");
  };

  const handleupdatePost = () => {
    return (
      <Container>
        {/* {JSON.stringify(post)} */}
        <Row className="mt-4">
          <Col>
            <Card color="dark" className="shadow" outline>
              <CardHeader className="text-center">
                <h3>Update Post</h3>
              </CardHeader>
              <CardBody>
                {/* {JSON.stringify(post.category)} */}

                <Form onSubmit={handlepostForm}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 2.15rem" }}>
                        Title:
                      </InputGroupText>
                      <Input
                        id="postTitle"
                        name="postTitle"
                        placeholder="Enter Title Here"
                        value={post.postTitle}
                        type="text"
                        onChange={(e) =>
                          handlePost(e.target.value, "postTitle")
                        }
                        invalid={
                          error.errors?.response?.data?.postTitle ? true : false
                        }
                      />
                      <FormFeedback>
                        {error.errors?.response?.data?.postTitle}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroupText>Content:</InputGroupText>

                    <JoditEditor
                      ref={editor}
                      id="postContent"
                      value={post.postContent}
                      name="postContent"
                      tabIndex={1} // tabIndex of textarea
                      // preferred to use only this option to update the content for performance reasons
                      onChange={(e) => handlePost(e, "postContent")}
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.2rem" }}>
                        Category:
                      </InputGroupText>
                      <Input
                        id="categoryId"
                        name="categoryId"
                        type="select"
                        onChange={(e) => handleCat(e)}
                        value={post.category.categoryId}
                        invalid={catFlag}
                      >
                        <option value={"-1"}>
                          Please select a category for the post
                        </option>

                        {categories.map((category) => (
                          <option
                            value={category.categoryId}
                            key={category.categoryId}
                          >
                            {category.categoryTitle}
                          </option>
                        ))}
                      </Input>
                      {catFlag && (
                        <FormFeedback>Category is required</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label>Please upload a photo for the post: </Label>

                    <Input
                      id="image"
                      name="image"
                      type="file"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </FormGroup>
                  <Container className="text-center mt-4">
                    <Button type="submit" color="success">
                      Update Post
                    </Button>
                    <Button
                      type="submit"
                      color="danger"
                      className="ms-2"
                      onClick={(e) => cancelUpdate(e)}
                    >
                      Cancel Update
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  return <Base>{handleupdatePost()}</Base>;
}

export default UpdatePost;
