import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroupText,
  InputGroup,
  FormFeedback,
} from "reactstrap";
import TabTitle from "../Util/TabTitle";
import { toast } from "react-toastify";
import Base from "../components/Base";
import { forgotPasswordOTP } from "../services/user-service";
import { useNavigate } from "react-router-dom";

const VerifyOtpPassword = () => {
  TabTitle("Reset Password");

  const navigate = useNavigate();
  const [data, setData] = useState({
    userEmail: "",
    userPassword: "",
    userOtp: "",
  });
  const resetData = () => {
    setData({
      userName: "",
      userEmail: "",
      userPassword: "",
      userAbout: "",
    });

    setConfirmPassword({
      Password: "",
    });

    handleCheckPassword(null);
  };

  const [confirmPassword, setConfirmPassword] = useState({
    Password: "",
  });

  const handleConfirmPassword = (event, property) => {
    setConfirmPassword({ ...confirmPassword, [property]: event.target.value });
  };

  const [checkPassword, setCheckPassword] = useState({
    message: null,
  });
  const handleCheckPassword = (value) => {
    setCheckPassword({ ...checkPassword, message: value });
  };

  const checkPasswordValidation = (event) => {
    let passValue = event.target.value;
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(passValue)) {
      handleCheckPassword("Password must not contain Whitespaces.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Uppercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Lowercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_])/;
    if (!isContainsSymbol.test(passValue)) {
      handleCheckPassword("Password must contain at least one Special Symbol.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(passValue)) {
      handleCheckPassword("Password must contain at least one Digit.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isValidLength = /^.{10,16}$/;
    if (!isValidLength.test(passValue)) {
      handleCheckPassword("Password must be 10-16 Characters Long.");

      return;
    } else {
      handleCheckPassword(null);
    }

    handleCheckPassword(null);
  };

  const handleChange = (event, property) => {
    setData({ ...data, [property]: event.target.value });
  };

  const submitForm = (event) => {
    event.preventDefault();

    forgotPasswordOTP(data)
      .then((response) => {
        console.log(response);
        toast.success(response.message);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Please try again. Email or Reset password code is invalid"
        );
      });
  };

  return (
    <Base>
      <Container>
        <Row className="mt-4">
          {/* {JSON.stringify(error.errors?.response?.data)} */}

          <Col sm={{ size: 6, offset: 3 }}>
            <Card className="shadow" color="success" outline>
              <CardHeader className="text-center">
                <h3>Fill Information to Reset Password</h3>
              </CardHeader>
              <CardBody>
                {/*creating signup form*/}
                <Form onSubmit={submitForm}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 3.26rem" }}>
                        Email
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter Email Here"
                        id="userEmail"
                        onChange={(e) => handleChange(e, "userEmail")}
                        value={data.userEmail}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1rem" }}>
                        {" "}
                        New Password
                      </InputGroupText>
                      <Input
                        type="password"
                        placeholder="Enter Password Here"
                        id="userPassword"
                        onChange={(e) => {
                          handleChange(e, "userPassword");
                          checkPasswordValidation(e);
                        }}
                        value={data.userPassword}
                        invalid={checkPassword.message !== null ? true : false}
                      />
                      {checkPassword.message !== null && (
                        <FormFeedback>{checkPassword.message}</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 0.2rem" }}>
                        Confirm Password
                      </InputGroupText>
                      <Input
                        type="Password"
                        placeholder="Enter Confirm Password Here"
                        id="confirmPassword"
                        onChange={(e) => handleConfirmPassword(e, "Password")}
                        value={confirmPassword.Password}
                        invalid={
                          data.userPassword !== confirmPassword.Password
                            ? true
                            : false
                        }
                      />
                      <FormFeedback>Password does not match</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 3.26rem" }}>
                        Code
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter Code Here"
                        id="userOtp"
                        onChange={(e) => handleChange(e, "userOtp")}
                        value={data.userOtp}
                      />
                    </InputGroup>
                  </FormGroup>
                  <Container className="text-center mt-4">
                    <Button
                      disabled={
                        data.userEmail === "" ||
                        data.userPassword === "" ||
                        data.userOtp === "" ||
                        data.userPassword !== confirmPassword.Password ||
                        checkPassword.message !== null
                      }
                      color="success"
                    >
                      Reset Password
                    </Button>
                    <Button
                      onClick={resetData}
                      color="danger"
                      type="reset"
                      className="ms-2"
                    >
                      Clear
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default VerifyOtpPassword;
