import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import Base from "../components/Base";
import { useState } from "react";
import { signup } from "../services/user-service";
import { toast } from "react-toastify";
import TabTitle from "../Util/TabTitle";

const Signup = () => {
  TabTitle("Signup");
  const [data, setData] = useState({
    userName: "",
    userEmail: "",
    userPassword: "",
    userAbout: "",
    userRole: "ROLE_USER",
  });

  const [confirmPassword, setConfirmPassword] = useState({
    Password: "",
  });
  const resetData = () => {
    setData({
      userName: "",
      userEmail: "",
      userPassword: "",
      userAbout: "",
    });

    setError({
      errors: {},
      isError: false,
    });

    setConfirmPassword({
      Password: "",
    });

    handleCheckPassword(null);
    // setConfirm(false);
  };

  const [error, setError] = useState({
    errors: {},
    isError: false,
  });

  const handleChange = (event, property) => {
    setData({ ...data, [property]: event.target.value });
  };

  const handleConfirmPassword = (event, property) => {
    setConfirmPassword({ ...confirmPassword, [property]: event.target.value });
  };

  const submitForm = (event) => {
    event.preventDefault();

    // if (data.userPassword !== confirmPassword.Password) {
    //   // setConfirm(true);
    //   return;
    // }
    signup(data)
      .then((response) => {
        toast.success(response.message);

        setData({
          userName: "",
          userEmail: "",
          userPassword: "",
          userAbout: "",
        });
        setError({
          errors: {},
          isError: false,
        });

        setConfirmPassword({
          Password: "",
        });

        handleCheckPassword(null);
      })
      .catch((error) => {
        setError({ errors: error, isError: true });
      });
  };

  const [checkPassword, setCheckPassword] = useState({
    message: null,
  });
  const handleCheckPassword = (value) => {
    setCheckPassword({ ...checkPassword, message: value });
  };

  const checkPasswordValidation = (event) => {
    let passValue = event.target.value;
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(passValue)) {
      handleCheckPassword("Password must not contain Whitespaces.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Uppercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Lowercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_])/;
    if (!isContainsSymbol.test(passValue)) {
      handleCheckPassword("Password must contain at least one Special Symbol.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(passValue)) {
      handleCheckPassword("Password must contain at least one Digit.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isValidLength = /^.{10,16}$/;
    if (!isValidLength.test(passValue)) {
      handleCheckPassword("Password must be 10-16 Characters Long.");

      return;
    } else {
      handleCheckPassword(null);
    }

    handleCheckPassword(null);
  };

  return (
    <Base>
      <Container>
        <Row className="mt-4">
          {/* {JSON.stringify(error.errors?.response?.data)} */}

          <Col sm={{ size: 6, offset: 3 }}>
            <Card className="shadow" color="success" outline>
              <CardHeader className="text-center">
                <h3>Fill Information to Register</h3>
              </CardHeader>
              <CardBody>
                {/*creating signup form*/}
                <Form onSubmit={submitForm}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.96rem" }}>
                        User Name
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter User Name Here"
                        id="userName"
                        onChange={(e) => handleChange(e, "userName")}
                        value={data.userName}
                        invalid={
                          error.errors?.response?.data?.userName ||
                          error.errors?.response?.data?.message ===
                            "Username is already taken"
                            ? true
                            : false
                        }
                      />
                      {error.errors?.response?.data?.message ===
                        "Username is already taken" && (
                        <FormFeedback>
                          {error.errors?.response?.data?.message}
                        </FormFeedback>
                      )}

                      <FormFeedback>
                        {error.errors?.response?.data?.userName}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 3.3rem" }}>
                        Email
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter Email Here"
                        id="userEmail"
                        onChange={(e) => handleChange(e, "userEmail")}
                        value={data.userEmail}
                        invalid={
                          error.errors?.response?.data?.userEmail ||
                          error.errors?.response?.data?.message ===
                            "Email already registered"
                            ? true
                            : false
                        }
                      />
                      {error.errors?.response?.data?.message ===
                        "Email already registered" && (
                        <FormFeedback>
                          {error.errors?.response?.data?.message}
                        </FormFeedback>
                      )}

                      <FormFeedback>
                        {error.errors?.response?.data?.userEmail}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 2.24rem" }}>
                        Password
                      </InputGroupText>
                      <Input
                        type="password"
                        placeholder="Enter Password Here"
                        id="userPassword"
                        onChange={(e) => {
                          handleChange(e, "userPassword");
                          checkPasswordValidation(e);
                        }}
                        value={data.userPassword}
                        invalid={
                          error.errors?.response?.data?.userPassword ||
                          checkPassword.message !== null
                            ? true
                            : false
                        }
                      />
                      {checkPassword.message !== null && (
                        <FormFeedback>{checkPassword.message}</FormFeedback>
                      )}
                      <FormFeedback>
                        {error.errors?.response?.data?.userPassword}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 0.2rem" }}>
                        Confirm Password
                      </InputGroupText>
                      <Input
                        type="Password"
                        placeholder="Enter Confirm Password Here"
                        id="confirmPassword"
                        onChange={(e) => handleConfirmPassword(e, "Password")}
                        value={confirmPassword.Password}
                        invalid={
                          data.userPassword !== confirmPassword.Password
                            ? true
                            : false
                        }
                      />
                      <FormFeedback>Password does not match</FormFeedback>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.65rem" }}>
                        About
                      </InputGroupText>

                      <Input
                        id="userAbout"
                        placeholder="Enter Here"
                        type="textarea"
                        style={{ height: "250px" }}
                        onChange={(e) => handleChange(e, "userAbout")}
                        value={data.userAbout}
                        invalid={
                          error.errors?.response?.data?.userAbout ? true : false
                        }
                      />
                      <FormFeedback>
                        {error.errors?.response?.data?.userAbout}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>

                  <Container className="text-center mt-4">
                    <Button
                      disabled={
                        data.userName === "" ||
                        data.userEmail === "" ||
                        data.userPassword === "" ||
                        data.userAbout === "" ||
                        data.userPassword !== confirmPassword.Password ||
                        checkPassword.message !== null
                      }
                      color="success"
                    >
                      Register
                    </Button>
                    <Button
                      onClick={resetData}
                      color="danger"
                      type="reset"
                      className="ms-2"
                    >
                      Reset
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default Signup;
