import { React, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  InputGroupText,
  InputGroup,
  FormText,
  ListGroup,
  ListGroupItem,
  CardFooter,
  Label,
  Spinner,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import { toast } from "react-toastify";
import Base from "../../components/Base";
import {
  createUser,
  deleteUserById,
  getAllUser,
  getUserByEmail,
} from "../../services/user-service";
import TabTitle from "../../Util/TabTitle";
import { Link } from "react-router-dom";

const CreateUser = (args) => {
  TabTitle("CreateUser");

  const initialCreateUserState = {
    userName: "",
    userEmail: "",
    userPassword: "",
    userAbout: "",
    userRole: "",
  };

  const [createUserState, setCreateUserState] = useState(
    initialCreateUserState
  );
  const [confirmPassword, setConfirmPassword] = useState({
    Password: "",
  });

  const resetCreateUserState = () => {
    setCreateUserState({
      userName: "",
      userEmail: "",
      userPassword: "",
      userAbout: "",
      userRole: "",
    });

    setConfirmPassword({
      Password: "",
    });

    handleCheckPassword(null);
  };

  const handleConfirmPassword = (event, property) => {
    setConfirmPassword({ ...confirmPassword, [property]: event.target.value });
  };

  const [checkPassword, setCheckPassword] = useState({
    message: null,
  });
  const handleCheckPassword = (value) => {
    setCheckPassword({ ...checkPassword, message: value });
  };

  const checkPasswordValidation = (event) => {
    let passValue = event.target.value;
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(passValue)) {
      handleCheckPassword("Password must not contain Whitespaces.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Uppercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(passValue)) {
      handleCheckPassword(
        "Password must have at least one Lowercase Character."
      );

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_])/;
    if (!isContainsSymbol.test(passValue)) {
      handleCheckPassword("Password must contain at least one Special Symbol.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(passValue)) {
      handleCheckPassword("Password must contain at least one Digit.");

      return;
    } else {
      handleCheckPassword(null);
    }

    const isValidLength = /^.{10,16}$/;
    if (!isValidLength.test(passValue)) {
      handleCheckPassword("Password must be 10-16 Characters Long.");

      return;
    } else {
      handleCheckPassword(null);
    }

    handleCheckPassword(null);
  };

  const handleFormChange = (event, property) => {
    setCreateUserState({ ...createUserState, [property]: event.target.value });
  };

  const initialUserListState = {
    content: [],
    pageNumber: "",
    pageSize: "",
    totalElements: "",
    totalPages: "",
    lastPage: false,
  };

  const [users, setUsers] = useState(initialUserListState);

  const initialSearchKeyword = {
    searchWord: "",
  };

  const [searchKeyword, setSearchKeyword] = useState(initialSearchKeyword);

  const handleSearchKeyword = (event) => {
    setSearchKeyword({ ...searchKeyword, searchWord: event.target.value });
  };

  const initialUserFilterState = {
    pageN: "0",
    pageS: "5",
    sortBy: "userId",
    sortDir: "asc",
  };

  const [userFilter, setUserFilter] = useState(initialUserFilterState);

  const resetUserFilter = () => {
    setSearchKeyword(initialSearchKeyword);
    setUserFilter(initialUserFilterState);
    getAllUser("0", "5", "userId", "asc")
      .then((userData) => {
        setUsers(userData);
      })
      .catch((userError) => {
        toast.error("Error: Unable in loading Users List");
      });
  };

  const handleUserFilter = (value, property) => {
    setUserFilter({ ...userFilter, [property]: value });
  };

  const [deleteUserId, setDeleteUserId] = useState({
    id: "",
  });
  const [userModal, setUserModal] = useState(false);
  const toggleUser = () => setUserModal(!userModal);
  const handledeleteId = (event) => {
    setDeleteUserId({ ...deleteUserId, id: event.target.value });
  };

  const handleDeleteUser = () => {
    deleteUserById(deleteUserId.id)
      .then((respone) => {
        toast.success(respone.message);
        getAllUser("0", "5", "userId", "asc")
          .then((userData) => {
            setUsers(userData);
          })
          .catch((userError) => {
            toast.error("Error: Unable in loading Users List");
          });
      })
      .catch((deleteError) => {
        toast.error(deleteError.response.data.message);
      });
  };

  useEffect(() => {
    getAllUser("0", "5", "userId", "asc")
      .then((userData) => {
        setUsers(userData);
      })
      .catch((userError) => {
        toast.error("Error: Unable in loading Users List");
      });
  }, []);

  const submitForm = (event) => {
    event.preventDefault();

    createUser(createUserState)
      .then((response) => {
        //console.log(response);
        toast.success(response.message);

        resetCreateUserState();
        setError({ errors: {}, isError: false });
      })
      .catch((error) => {
        //console.log(error);
        toast.error(error.response.data.message);
        setError({ errors: error, isError: true });
      });
  };

  const [error, setError] = useState({
    errors: {},
    isError: false,
  });

  const changePage = (pageN, pageS, sortBy, sortDir) => {
    getAllUser(pageN, pageS, sortBy, sortDir)
      .then((data) => {
        setUsers({
          content: [...users.content, ...data.content],
          pageNumber: data.pageNumber,
          pageSize: data.pageSize,
          totalElements: data.totalElements,
          totalPages: data.totalPages,
          lastPage: data.lastPage,
        });
      })
      .catch((error) => {
        toast.error("Error: Unable in loading User");
      });
  };

  const searchPage = () => {
    if (searchKeyword.searchWord !== "") {
      getUserByEmail(searchKeyword.searchWord)
        .then((userEmailData) => {
          setUsers({
            ...users,
            content: [userEmailData],
            pageNumber: "0",
            pageSize: "1",
            totalElements: "1",
            totalPages: "1",
            lastPage: true,
          });
        })
        .catch((error) => {
          toast.error("Error: Could not find user email");
        });
    }

    if (searchKeyword.searchWord === "") {
      getAllUser(
        userFilter.pageN,
        userFilter.pageS,
        userFilter.sortBy,
        userFilter.sortDir
      )
        .then((userData) => {
          setUsers(userData);
          console.log(users);
        })
        .catch((error) => {
          toast.error("Error: Unable in loading Users");
        });
    }
  };

  return (
    <Base>
      <Container>
        <Row className="mt-4">
          {/* {JSON.stringify(data)} */}
          <Col sm={{ size: 6, offset: 3 }}>
            <Card className="shadow" color="success" outline>
              <CardHeader className="text-center">
                <h3>Fill Information to Create User</h3>
              </CardHeader>
              <CardBody>
                {/*creating signup form*/}
                <Form onSubmit={submitForm}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.94rem" }}>
                        User Name
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter User Name Here"
                        id="userName"
                        onChange={(e) => handleFormChange(e, "userName")}
                        value={createUserState.userName}
                        invalid={
                          error.errors?.response?.data?.userName ||
                          error.errors?.response?.data?.message ===
                            "Username is already taken"
                            ? true
                            : false
                        }
                      />
                      {error.errors?.response?.data?.message ===
                        "Username is already taken" && (
                        <FormFeedback>
                          {error.errors?.response?.data?.message}
                        </FormFeedback>
                      )}

                      <FormFeedback>
                        {error.errors?.response?.data?.userName}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 3.3rem" }}>
                        Email
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter Email Here"
                        id="userEmail"
                        onChange={(e) => handleFormChange(e, "userEmail")}
                        value={createUserState.userEmail}
                        invalid={
                          error.errors?.response?.data?.userEmail ||
                          error.errors?.response?.data?.message ===
                            "Email already registered"
                            ? true
                            : false
                        }
                      />
                      {error.errors?.response?.data?.message ===
                        "Email already registered" && (
                        <FormFeedback>
                          {error.errors?.response?.data?.message}
                        </FormFeedback>
                      )}

                      <FormFeedback>
                        {error.errors?.response?.data?.userEmail}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 2.22rem" }}>
                        Password
                      </InputGroupText>
                      <Input
                        type="password"
                        placeholder="Enter Password Here"
                        id="userPassword"
                        onChange={(e) => {
                          handleFormChange(e, "userPassword");
                          checkPasswordValidation(e);
                        }}
                        value={createUserState.userPassword}
                        invalid={
                          error.errors?.response?.data?.userPassword ||
                          checkPassword.message !== null
                            ? true
                            : false
                        }
                      />
                      {checkPassword.message !== null && (
                        <FormFeedback>{checkPassword.message}</FormFeedback>
                      )}
                      <FormFeedback>
                        {error.errors?.response?.data?.userPassword}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 0.2rem" }}>
                        Confirm Password
                      </InputGroupText>
                      <Input
                        type="Password"
                        placeholder="Enter Confirm Password Here"
                        id="confirmPassword"
                        onChange={(e) => handleConfirmPassword(e, "Password")}
                        value={confirmPassword.Password}
                        invalid={
                          createUserState.userPassword !==
                          confirmPassword.Password
                            ? true
                            : false
                        }
                      />
                      <FormFeedback>Password does not match</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.45rem" }}>
                        About
                      </InputGroupText>

                      <Input
                        id="userAbout"
                        placeholder="Enter Here"
                        type="textarea"
                        style={{ height: "250px" }}
                        onChange={(e) => handleFormChange(e, "userAbout")}
                        value={createUserState.userAbout}
                        invalid={
                          error.errors?.response?.data?.userAbout ? true : false
                        }
                      />
                      <FormFeedback>
                        {error.errors?.response?.data?.userAbout}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.85rem" }}>
                        Role
                      </InputGroupText>
                      <FormGroup className="ms-3 mt-2" check inline>
                        <Input
                          type="radio"
                          name="userRole"
                          onChange={(e) => handleFormChange(e, "userRole")}
                          value={"ROLE_USER"}
                          invalid={
                            error.errors?.response?.data?.userRole
                              ? true
                              : false
                          }
                        />
                        User
                      </FormGroup>

                      <FormGroup className="mt-2" check inline>
                        <Input
                          type="radio"
                          name="userRole"
                          onChange={(e) => handleFormChange(e, "userRole")}
                          value={"ROLE_ADMIN"}
                          invalid={
                            error.errors?.response?.data?.userRole
                              ? true
                              : false
                          }
                        />
                        Admin
                      </FormGroup>

                      <FormText color="danger">
                        {error.errors?.response?.data?.userRole}
                      </FormText>
                    </InputGroup>
                  </FormGroup>

                  <Container className="text-center mt-4">
                    <Button
                      disabled={
                        createUserState.userName === "" ||
                        createUserState.userEmail === "" ||
                        createUserState.userPassword === "" ||
                        createUserState.userAbout === "" ||
                        createUserState.userRole === "" ||
                        createUserState.userPassword !==
                          confirmPassword.Password ||
                        checkPassword.message !== null
                      }
                      onClick={submitForm}
                      color="success"
                    >
                      Register
                    </Button>
                    <Button
                      onClick={resetCreateUserState}
                      outline
                      color="danger"
                      type="reset"
                      className="ms-2"
                    >
                      Reset
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Container className="mt-3">
            {/* Search Functionality of the Blog Start */}
            {/* {JSON.stringify(searchKeyword)} */}
            <Card
              color="success"
              outline
              style={{
                width: "auto",
              }}
            >
              <CardHeader className="text-center" tag="h1">
                Search Users
              </CardHeader>
              <Form className="px-3" onSubmit={searchPage}>
                <FormGroup>
                  <Row lg={6}>
                    <Col>
                      <Label>Sort Order:</Label>
                    </Col>
                    <Col>
                      <FormGroup check>
                        <Input
                          name="sortDir"
                          type="radio"
                          onChange={() => handleUserFilter("asc", "sortDir")}
                          defaultChecked
                        />{" "}
                        <Label>Ascending</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check>
                        <Input
                          name="sortDir"
                          type="radio"
                          onChange={() => handleUserFilter("dsc", "sortDir")}
                        />{" "}
                        <Label>Descending</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row lg={6}>
                    <Col>
                      <Label>Sort By:</Label>
                    </Col>
                    <Col>
                      <FormGroup check>
                        <Input
                          name="SortBy"
                          type="radio"
                          onChange={() =>
                            handleUserFilter("userName", "SortBy")
                          }
                          defaultChecked
                        />{" "}
                        <Label>User Name</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup check>
                        <Input
                          name="SortBy"
                          type="radio"
                          onChange={() =>
                            handleUserFilter("userEmail", "SortBy")
                          }
                        />{" "}
                        <Label>User Email</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="searchKeyword">Search User:</Label>
                  <Input
                    id="searchKeyword"
                    name="searchKeyword"
                    placeholder="Enter Email..."
                    type="search"
                    value={searchKeyword.searchWord}
                    onChange={(e) => handleSearchKeyword(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <div className="text-center">
                    <Button
                      outline
                      onClick={(e) => {
                        searchPage(e);
                      }}
                      color="success"
                    >
                      Search
                    </Button>
                    <Button
                      onClick={resetUserFilter}
                      outline
                      color="danger"
                      type="reset"
                      className="ms-2"
                    >
                      Reset
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </Card>

            {/* Search Functionality of the Blog End */}
            <Card
              className="mt-1 mb-1"
              style={{
                width: "auto",
              }}
              color="success"
              outline
            >
              <CardHeader className="text-center">
                <h1>User List: ({users.totalElements})</h1>
                {/* {JSON.stringify(postFilter)} */}
              </CardHeader>
            </Card>
            <Row className="mt-3">
              <Col>
                {/* Post Card Start */}

                <InfiniteScroll
                  dataLength={users.content.length}
                  next={() =>
                    changePage(
                      users.pageNumber + 1,
                      userFilter.pageS,
                      userFilter.sortBy,
                      userFilter.sortDir
                    )
                  }
                  hasMore={!users.lastPage} // Replace with a condition based on your data source
                  loader={
                    <div className="text-center mt-4 spinner-border-md ">
                      <Spinner color="success">Loading...</Spinner>
                    </div>
                  }
                  endMessage={
                    <h4 className="text-center ">No more users to load.</h4>
                  }
                >
                  {users.content?.map((user) => (
                    <Card
                      key={user.userId}
                      style={{
                        width: "50%",
                      }}
                      className="mb-3 shadow mx-auto"
                      color="success"
                      outline
                    >
                      <CardHeader tag="h4" className="text-center">
                        {user.userName}
                      </CardHeader>
                      <ListGroup flush>
                        <ListGroupItem className="text-center">
                          <b className="text-success">User Email:</b>{" "}
                          {user.userEmail}
                        </ListGroupItem>
                        <ListGroupItem className="text-center">
                          <b className="text-success">User Role:</b>{" "}
                          {user.roles.map(
                            (item) =>
                              item.roleName.toString().substring(5).charAt(0) +
                              item.roleName
                                .toString()
                                .substring(6)
                                .toLowerCase()
                          )}
                        </ListGroupItem>
                      </ListGroup>

                      <CardBody>
                        <CardText className="text-center">
                          {" "}
                          <b className="text-success">About:</b>
                        </CardText>
                        <CardText
                          className="text-center"
                          dangerouslySetInnerHTML={{
                            __html: user.userAbout?.substring(0, 30) + "...",
                          }}
                        ></CardText>
                      </CardBody>
                      <CardFooter>
                        <div>
                          <Link
                            to={"/admin/userUpdateAdmin/" + user.userId}
                            type="button"
                            className="btn btn-outline-warning ms-2 justify-content-center "
                          >
                            Update
                          </Link>

                          <Button
                            className="float-end"
                            value={user.userId}
                            key={user.userId}
                            outline
                            color="danger"
                            onClick={(e) => {
                              toggleUser();
                              handledeleteId(e);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </CardFooter>
                      <div>
                        <Modal isOpen={userModal} toggle={toggleUser} {...args}>
                          <ModalHeader toggle={toggleUser}>
                            Delete User
                          </ModalHeader>
                          <ModalBody>
                            <b>Do you want to Delete this user?</b>
                          </ModalBody>
                          <ModalFooter>
                            <Container className="text-center mt-4">
                              <Button
                                color="danger"
                                onClick={() => {
                                  handleDeleteUser();
                                  toggleUser();
                                }}
                              >
                                Yes
                              </Button>{" "}
                              <Button color="success" onClick={toggleUser}>
                                No
                              </Button>
                            </Container>
                          </ModalFooter>
                        </Modal>
                      </div>
                    </Card>
                  ))}
                </InfiniteScroll>
                {/* {error && <p>Error: {error.message}</p>} */}

                {/* Post Card End */}
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </Base>
  );
};

export default CreateUser;
