import Base from "../../components/Base";
import AddPost from "../../components/AddPost";
import { Container } from "reactstrap";
import TabTitle from "../../Util/TabTitle";

const Userdashboard = () => {
  TabTitle("dashboard");

  return (
    <Base>
      <Container>
        <AddPost></AddPost>
      </Container>
    </Base>
  );
};

export default Userdashboard;
