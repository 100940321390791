import TabTitle from "../Util/TabTitle";
import Base from "../components/Base";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
  CardHeader,
  CardImg,
} from "reactstrap";
const About = () => {
  TabTitle("About");
  var defaultAboutImage = require("../defaultImage/About.jpg");

  return (
    <Base>
      <Container className="mt-4">
        <Card className="my-2 shadow">
          <CardHeader tag="h2" className="text-center">
            About
          </CardHeader>
          <CardImg
            alt="About Image"
            src={defaultAboutImage}
            style={{
              height: 180,
            }}
            top
            width="100%"
          />

          <CardBody>
            <CardTitle className="text-center" tag="h1">
              Armeen Blog
            </CardTitle>

            <CardText>
              <p>
                Welcome to Armeen Blog! We are a diverse and vibrant community
                of writers, creators, and readers passionate about exploring the
                wonders of life. At Armeen Blog, our mission is to provide a
                thought-provoking and enriching experience for anyone seeking
                inspiration, knowledge, or a moment of connection. Our blog
                covers a wide array of topics, ranging from lifestyle and travel
                to culture, technology, and beyond. Whether you're a seasoned
                explorer, an avid learner, or someone just looking for a moment
                of relaxation, you'll find something to captivate your interest
                here. We believe in the power of storytelling and the impact it
                can have on our lives. Our team of dedicated writers is
                committed to bringing you engaging content that sparks
                curiosity, ignites creativity, and encourages meaningful
                conversations. We invite you to join our growing community,
                where diverse perspectives and voices come together to celebrate
                the beauty of life's journey. At Armeen Blog, inclusivity and
                openness are at the heart of what we do. We're more than just a
                blog; we're a collective of individuals united by a shared love
                for exploration and discovery. Thank you for being a part of our
                community. Let's embark on this adventure together as we
                navigate the vast landscape of ideas, experiences, and shared
                stories. Welcome to Armeen Blog - where every page is an
                invitation to explore the extraordinary in the ordinary.
              </p>
            </CardText>
          </CardBody>
        </Card>
      </Container>
    </Base>
  );
};
export default About;
