import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { isLoggedIn } from "../auth";

function Privateroute() {
  // const navigate = useNavigate();
  if (isLoggedIn()) {
    // let currentTime = new Date().getTime();
    // let tokenExpirationTime = getTokenExpiration();

    // let tokenExpireTimer = tokenExpirationTime - currentTime;

    // autoLogout(tokenExpireTimer, () => {
    //   navigate("/login");
    // });
    return <Outlet />;
  }

  if (!isLoggedIn()) {
    return <Navigate to={"/login"} />;
  }
}

export default Privateroute;
