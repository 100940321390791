import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
  Container,
  Row,
  Col,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { useEffect, useRef, useState, React } from "react";
import { createPost, uploadImage } from "../services/postService";
import { getCurrentUserDetail } from "../auth";
import { toast } from "react-toastify";
import { getAllCategory } from "../services/categoryService";
import JoditEditor from "jodit-react";

const AddPost = () => {
  const [categories, setCategories] = useState([]);
  const editor = useRef(null);

  useEffect(() => {
    getAllCategory()
      .then((catData) => {
        //console.log(catData);
        setCategories(catData);
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Error:Unable to load Categories");
      });
  }, []);

  const [post, setPost] = useState({
    postTitle: "",
    postContent: "",
    postImageName: "",
  });

  const [imageFlag, setImageFlag] = useState(false);
  const [image, setImage] = useState(null);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const [cat, setCat] = useState({
    categoryId: "-1",
  });

  const [catFlag, setCatFlag] = useState(false);

  const resetPost = () => {
    setPost({
      postTitle: "",
      postContent: "",
      postImageName: "",
    });
    setCat({
      categoryId: "",
    });
  };

  const handleCat = (event, property) => {
    setCat({ ...cat, [property]: event.target.value });
  };

  const handlePost = (event, property) => {
    setPost({ ...post, [property]: event });
  };

  const [error, setError] = useState({
    errors: {},
    isError: false,
  });

  const handlepostForm = (event) => {
    event.preventDefault();

    if (post.postContent.trim() === "") {
      toast.error("Content is required");
      return;
    }

    if (image === null) {
      setImageFlag(true);
      return;
    } else {
      setImageFlag(false);
    }
    if (cat.categoryId === "-1") {
      setCatFlag(true);
      return;
    } else {
      setCatFlag(false);
    }

    //console.log(cat.categoryId)
    let user = getCurrentUserDetail();
    let userId = user.id;
    let catId = cat.categoryId;

    createPost(post, userId, catId)
      .then((response) => {
        uploadImage(response.postId, image)
          .then((imageData) => {
            //console.log(imageData);
          })
          .catch((error) => {
            //console.log(error);
            toast.error("Error:Unable to upload Image");
          });

        //console.log(response);

        //navigate("/user/dashboard");
        toast.success("Post successful");

        setPost({
          postTitle: "",
          postContent: "",
          postImageName: "",
        });
        setCat({
          categoryId: "-1",
        });
        setCatFlag(false);
        setImageFlag(false);
        setError({
          errors: {},
          isError: false,
        });
      })
      .catch((error) => {
        //console.log(error);
        //toast.error("Error:Unable to create Post");
        setError({ errors: error, isError: true });
      });
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Card color="dark" className="shadow" outline>
            <CardHeader className="text-center">
              <h3>Fill Information For Post</h3>
            </CardHeader>
            <CardBody>
              {/* {JSON.stringify(post)} */}
              {/* {JSON.stringify(cat)} */}
              {/* {JSON.stringify(categories)} */}
              <Form onSubmit={handlepostForm}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText style={{ padding: "0.375rem 2.15rem" }}>
                      Title:
                    </InputGroupText>
                    <Input
                      id="postTitle"
                      name="postTitle"
                      placeholder="Enter Title Here"
                      value={post.postTitle}
                      type="text"
                      onChange={(e) => handlePost(e.target.value, "postTitle")}
                      invalid={
                        error.errors?.response?.data?.postTitle ? true : false
                      }
                    />
                    <FormFeedback>
                      {error.errors?.response?.data?.postTitle}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroupText>Content:</InputGroupText>

                  <JoditEditor
                    ref={editor}
                    id="postContent"
                    value={post.postContent}
                    name="postContent"
                    tabIndex={1} // tabIndex of textarea
                    // preferred to use only this option to update the content for performance reasons
                    onChange={(e) => handlePost(e, "postContent")}
                  />
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText style={{ padding: "0.375rem 1.2rem" }}>
                      Category:
                    </InputGroupText>
                    <Input
                      id="categoryId"
                      name="categoryId"
                      type="select"
                      onChange={(e) => handleCat(e, "categoryId")}
                      value={cat.categoryId}
                      invalid={catFlag}
                    >
                      <option value={"-1"} key={"-1"}>
                        Please select a category for the post
                      </option>

                      {categories.map((category) => (
                        <option
                          value={category.categoryId}
                          key={category.categoryId}
                        >
                          {category.categoryTitle}
                        </option>
                      ))}
                    </Input>

                    {catFlag && (
                      <FormFeedback>Category is required</FormFeedback>
                    )}
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <Label>Please upload a photo for the post: </Label>

                  <Input
                    id="image"
                    name="image"
                    type="file"
                    onChange={(e) => handleImageChange(e)}
                    invalid={imageFlag}
                  />
                  {imageFlag && (
                    <FormFeedback>Post Photo is required</FormFeedback>
                  )}
                </FormGroup>
                <Container className="text-center mt-4">
                  <Button type="submit" color="success">
                    Create Post
                  </Button>
                  <Button
                    color="danger"
                    type="reset"
                    className="ms-2"
                    onClick={resetPost}
                  >
                    Reset Post
                  </Button>
                </Container>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddPost;
