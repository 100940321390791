import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import Base from "../../components/Base";
import { useState } from "react";
import { toast } from "react-toastify";
import { getUserById, updateUser } from "../../services/user-service";
import TabTitle from "../../Util/TabTitle";
const UserUpdate = () => {
  TabTitle("UserUpdate");
  const { userId } = useParams();

  const [user, setUser] = useState({
    userName: "",
    userAbout: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    getUserById(userId)
      .then((userData) => {
        setUser(userData);
      })
      .catch((userError) => {
        toast.error("Error: Unable in loading User details");
        navigate("/user/profileinfo");
      });
  }, [userId, navigate]);

  const handleUpdateUser = (event) => {
    event.preventDefault();

    updateUser(user, userId)
      .then((response) => {
        //console.log(response);
        toast.success("Update successful");

        navigate("/user/profileinfo");
      })
      .catch((error) => {
        //console.log(error);
        if (
          error.response.data.message === "Error: Username is already taken!"
        ) {
          toast.error(error.response.data.message);
        }
        setError({ errors: error, isError: true });
      });
  };

  const cancelUpdate = (event) => {
    event.preventDefault();
    navigate("/user/profileinfo");
  };

  const [error, setError] = useState({
    errors: {},
    isError: false,
  });

  const handleChange = (event, property) => {
    setUser({ ...user, [property]: event.target.value });
  };

  return (
    <Base>
      <Container>
        <Row className="mt-4">
          {/* {JSON.stringify(data)} */}
          <Col sm={{ size: 6, offset: 3 }}>
            <Card className="shadow" color="success" outline>
              <CardHeader className="text-center">
                <h3>Update Profile</h3>
              </CardHeader>
              <CardBody>
                {/*creating signup form*/}
                <Form onSubmit={handleUpdateUser}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 0.235rem" }}>
                        User Name
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder="Enter Name Here"
                        id="userName"
                        onChange={(e) => handleChange(e, "userName")}
                        value={user.userName}
                        invalid={
                          error.errors?.response?.data?.userName ||
                          error.errors?.response?.data?.message ===
                            "Username is already taken"
                            ? true
                            : false
                        }
                      />
                      {error.errors?.response?.data?.message ===
                        "Username is already taken" && (
                        <FormFeedback>
                          {error.errors?.response?.data?.message}
                        </FormFeedback>
                      )}

                      <FormFeedback>
                        {error.errors?.response?.data?.userName}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <InputGroupText style={{ padding: "0.375rem 1.45rem" }}>
                        About
                      </InputGroupText>

                      <Input
                        id="userAbout"
                        placeholder="Enter Here"
                        type="textarea"
                        style={{ height: "250px" }}
                        onChange={(e) => handleChange(e, "userAbout")}
                        value={user.userAbout}
                        invalid={
                          error.errors?.response?.data?.userAbout ? true : false
                        }
                      />
                      <FormFeedback>
                        {error.errors?.response?.data?.userAbout}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>

                  <Container className="text-center mt-4">
                    <Button outline color="success">
                      Update
                    </Button>
                    <Button
                      onClick={cancelUpdate}
                      outline
                      color="danger"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default UserUpdate;
