import { myAxios, privateAxios } from "./helper";

export const createPost = (post, userId, categoryId) => {
  return privateAxios
    .post("/api/usr/" + userId + "/cat/" + categoryId + "/posts", post)
    .then((response) => response.data);
};

export const getAllPost = (pageN, pageS, sortBy, sortDir) => {
  return myAxios
    .get(
      "/api/posts?pageN=" +
        pageN +
        "&pageS=" +
        pageS +
        "&sortBy=" +
        sortBy +
        "&sortDir=" +
        sortDir
    )
    .then((response) => response.data);
};

export const getOnePost = (postId) => {
  return myAxios.get("/api/posts/" + postId).then((response) => response.data);
};

export const uploadImage = (postId, image) => {
  let formData = new FormData();
  formData.append("image", image);
  return privateAxios
    .post("/api/post/image/upload/" + postId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export const searchPost = (searchKeyword, pageN, pageS, sortBy, sortDir) => {
  return myAxios
    .get(
      "/api/posts/search/" +
        searchKeyword +
        "?pageN=" +
        pageN +
        "&pageS=" +
        pageS +
        "&sortBy=" +
        sortBy +
        "&sortDir=" +
        sortDir
    )
    .then((response) => response.data);
};

export const getPostByCategory = (
  categoryId,
  pageN,
  pageS,
  sortBy,
  sortDir
) => {
  return myAxios
    .get(
      "/api/category/" +
        categoryId +
        "/posts?pageN=" +
        pageN +
        "&pageS=" +
        pageS +
        "&sortBy=" +
        sortBy +
        "&sortDir=" +
        sortDir
    )
    .then((response) => response.data);
};

export const getPostByUser = (userId, pageN, pageS, sortBy, sortDir) => {
  return myAxios
    .get(
      "/api/user/" +
        userId +
        "/posts?pageN=" +
        pageN +
        "&pageS=" +
        pageS +
        "&sortBy=" +
        sortBy +
        "&sortDir=" +
        sortDir
    )
    .then((response) => response.data);
};

export const deletePost = (postId) => {
  return privateAxios
    .delete("/api/delete/post/" + postId)
    .then((response) => response.data);
};


export const updatePost = (post, userId, categoryId,postId) => {
  return privateAxios
    .put("/api/usr/" + userId + "/cat/" + categoryId + "/post/"+postId, post)
    .then((response) => response.data);
};